enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_SHOPS = "getShops",
  GET_SHOP = "getShop",
  CREATE_SHOP = "createShop",
  UPDATE_SHOP = "updateShop",
  GET_BUDGET_YEARS = "getBudgetYears",
  GET_BUDGET_YEAR = "getBudgetYear",
  GET_BUDGET_YEAR_OPTIONS = "getBudgetYearOptions",
  CREATE_BUDGET_YEAR = "createBudgetYear",
  UPDATE_BUDGET_YEAR = "updateBudgetYear",
  GET_BUDGET_QUARTERS = "getBudgetQuarters",
  GET_BUDGET_QUARTER = "getBudgetQuarter",
  GET_BUDGET_QUARTER_BY_PARAMS = "getBudgetQuarterByParams",
  GET_BUSINESS_DISTRICTS = "getBusinessDistricts",
  GET_BUSINESS_DISTRICT = "getDBusinessistrict",
  CREATE_BUSINESS_DISTRICT = "createBusinessDistrict",
  UPDATE_BUSINESS_DISTRICT = "updateBusinessDistrict",
  GET_ADMINS = "getAdmins",
  GET_ADMIN = "getAdmin",
  CREATE_ADMIN = "createAdmin",
  UPDATE_ADMIN = "updateAdmin",
  GET_DISTRIBUTION_USERS = "getDistributionUsers",
  GET_DISTRIBUTIONS = "getDistributions",
  GET_DISTRIBUTION = "getDistribution",
  CREATE_DISTRIBUTION = "createDistribution",
  UPDATE_DISTRIBUTION = "updateDistribution",
  SHIFT_BUDGET = "shiftBudget",
  ADD_BUDGET = "addBudget",
  GET_COUPON_ITEMS = "getCouponItems",
  GET_COUPON_ITEM = "getCouponItem",
  CREATE_COUPON_ITEM = "createCouponItem",
  UPDATE_COUPON_ITEM = "updateCouponItem",
  UPDATE_COUPON_ITEM_SHOPS = "updateCouponItemShops",
  DELETE_COUPON_ITEM = "deleteCouponITtem",
  GET_COUPON_DELIVER_EVENTS = "getConponDeliverEvents",
  GET_COUPON_DELIVER_EVENT = "getConponDeliverEvent",
  CREATE_COUPON_DELIVER_EVENT = "createConponDeliverEvent",
  UPDATE_COUPON_DELIVER_EVENT = "updateConponDeliverEvent",
  GET_COUPON_SETTINGS = "getCouponSettings",
  UPDATE_COUPON_SETTINGS = "updateCouponSettings",
  GET_COUPON_DELIVER_EVENT_ITEMS = "getCouponDeliverEventItems",
  UPDATE_COUPON_DELIVER_EVENT_ITEMS = "updateCouponDeliverEventItems",
  GET_USERS = "getUsers",
  GET_ORDERS = "getOrders",
  GET_ORDER = "getOrder",
  GET_ROLES = "getRoles",
  GET_EVENTS = "getEvents",
  GET_EVENT = "getEvent",
  CREATE_EVENT = "createEvent",
  UPDATE_EVENT = "updateEvent",
  UPDATE_SHOP_LOGIN = "updateShopLogin",
  GET_SHOP_LOGIN = "getShopLogin",
  UPDATE_EVENT_ADMINS = "updateEventAdmins",
  SUBMIT_EVENT = "submitEvent",
  SUSPEND_EVENT = "suspend_event",
  APPROVE_EVENT = "approveEvent",
  UPDATE_EVENT_INVITATIONS = "update_event_invitations",
  GET_EVENT_INVITATIONS = "get_event_invitations",
  GET_PENDING_EVENTS = "get_pending_events",
  REQUIRE_EVENT_APPROVE = "require_event_approve",
  REQUIRE_EVENT_DENY = "require_event_deny",
  GET_SHOP_BANNERS = "get_shop_banners",
  GET_USER_BANNERS = "get_user_banners",
  UPDATE_SYSTEM_SETTING = "update_system_setting",
  GET_SYSTEM_SETTING = "get_system_setting",
  UPDATE_EVENT_GIFT_COUPONS = "update_event_gift_coupon",
  GET_GIFT_COUPONS = "get_gift_coupons",
  GET_EVENT_REGISTRATIONS = "get_event_registrations",
  GET_NEWS_ITEMS = "getNewsItems",
  GET_NEWS_ITEM = "getNewsItem",
  CREATE_NEWS_ITEM = "createNewsItem",
  UPDATE_NEWS_ITEM = "updateNewsItem",

  GET_COMPOUNDS = "getCompounds",
  GET_COMPOUND = "getCompound",
  CREATE_COMPOUND = "createCompound",
  UPDATE_COMPOUND = "updateCompound",
  DELETE_COMPOUND = "deleteCompound",
  GET_IPS = "getIps",
  GET_IP = "getIp",
  CREATE_IP = "createIp",
  UPDATE_IP = "updateIp",
  DELETE_IP = "deleteIp",
  GET_PRODUCT_BRANDS = "getProductBrands",
  GET_PRODUCT_BRAND = "getProductBrand",
  CREATE_PRODUCT_BRAND = "createProductBrand",
  UPDATE_PRODUCT_BRAND = "updateProductBrand",
  DELETE_PRODUCT_BRAND = "deleteProductBrand",
  GET_ALL_PRODUCTS = "getAllProducts",
  GET_PRODUCTS = "getProducts",
  GET_PRODUCT = "getProduct",
  CREATE_PRODUCT = "createProduct",
  UPDATE_PRODUCT = "updateProduct",
  DELETE_PRODUCT = "deleteProduct",
  ABANDON_PRODUCT = "abandonProduct",
  UPLOAD_PRODUCTS = "uploadProducts",
  GET_DOWNLOAD_PRODUCTS = "getDownloadProducts",
  GET_DOWNLOAD_PAYMENT_RECORDS = "getDownloadPaymentRecords",
  GET_DOWNLOAD_ADD_BALANCE_RECORDS = "getDownloadAddBalanceRecords",
  GET_DOWNLOAD_GIFT_RECORDS = "getDownloadGiftRecords",
  GET_DOWNLOAD_MEMBERS = "getDownloadMembers",
  GET_DOWNLOAD_LOSER_ITEMS = "getDownloadLoserItems",
  GET_BOX_INFOS = "getBoxInfos",
  GET_BOX_INFO = "getBoxInfo",
  GET_BOXES = "getBoxes",
  GET_BOX_RECORDS = "getBoxRecords",
  CREATE_BOX_INFO = "createBoxInfo",
  UPDATE_BOX_INFO = "updateBoxInfo",
  GET_BOX_TYPES = "getBoxTypes",
  GET_GAME_TYPES = "getGameTypes",
  GET_BOX_ITEM_LEVELS = "getBoxItemLevels",
  GET_BOX_ITEM_TYPES = "getBoxItemTypes",
  GET_BOX_LEVEL_TYPES = "getBoxLevelTypes",
  GET_BOX_CATEGORIES = "getBoxCategories",
  GET_BOX_CATEGORY = "getBoxCategory",
  CREATE_BOX_CATEGORY = "createBoxCategory",
  UPDATE_BOX_CATEGORY = "updateBoxCategory",
  DELETE_BOX_CATEGORY = "deleteBoxCategory",
  CREATE_BOXES = "createBoxes",
  UPDATE_BOX_ENABLED = "updateBoxEnabled",

  GET_MEMBERS = "getMembers",
  GET_MEMBER = "getMember",
  UPDATE_MEMBER = "updateMember",
  ADD_MEMBER_BALANCE = "addMemberBalance",
  ADD_MEMBER_POINT = "addMemberPoint",

  GET_REPORT_DAYS = "getReportDays",

  GET_PRODUCT_BRAND_OPTIONS = "getProductBrandOptions",
  GET_PRODUCT_IP_OPTIONS = "getProductIpOptions",
  GET_PRODUCT_RECYCLE_TYPE_OPTIONS = "getProductRecycleTypeOptions",
  GET_BANNERS = "getBanners",
  UPDATE_BANNERS = "updateBanners",
  GET_BOX_INFO_BOX_TYPE_OPTIONS = "getBoxInfoBoxTypeOptions",
  GET_REMOVE_BALANCE_RECORDS = "getRemoveBalanceRecords",
  CREATE_REMOVE_BALANCE_RECORD = "createRemoveBalanceRecord",
  GET_PAYMENT_RECORDS = "getPaymentRecords",
  GET_ADD_BALANCE_RECORDS = "getAddBalanceRecords",
  CREATE_ADD_BALANCE_RECORD = "createAddBalanceRecord",
  GET_ADD_POINT_RECORDS = "getAddPointRecords",
  CREATE_ADD_POINT_RECORD = "createAddPointRecord",
  GET_ADD_EXTRA_POINT_RECORDS = "getAddExtraPointRecords",
  CREATE_ADD_EXTRA_POINT_RECORD = "createAddExtraPointRecord",
  ABORT_ADD_EXTRA_POINT_RECORD = "abortAddExtraPointRecord",
  ABORT_ADD_BALANCE_RECORD = "abortAddBalanceRecord",
  GET_SHIPPING_ORDERS = "getShippingOrders",
  UPDATE_SHIPPING_ORDER = "createShippingOrderAddPointRecord",
  GET_SHIPPING_ORDER = "getShippingOrder",
  GET_SUMMARY = "getSummary",
  GET_WATER_LIST = "getWaterList",
  GET_LOSER_LIST = "getLoserList",
  GET_CARD_RECORDS = "getCardRecords",
  GET_WATER_ITEMS = "getWaterItems",
  GET_LOSER_ITEMS = "getLoserItems",
  CONFIRM_REMOVE_BALANCE_RECORD = "confirmRemoveBalanceRecord",
  ABORT_REMOVE_BALANCE_RECORD = "abortRemoveBalanceRecord",
  GET_EVENT_OPTIONS = "getEventOptions",
  GET_BOX_PRODUCTS = "getBoxProducts",
  GET_LEVELS = "getLevels",
  GET_BOX_LEVEL_ITEM_INFO_GIFT_TYPES = "getBoxLevelItemInfoGiftTypes",
  GET_GIFT_RECORDS = "getGiftRecords",
  GET_GIFT_RECORD = "getGiftRecord",
  CREATE_GIFT_RECORD = "create_gift_record",
  DELETE_DISTRIBUTION = "deleteDistribution",
  DELETE_DISTRIBUTION_USER = "deleteDistributionUser",
  GET_RELATIVE_BOX_INFOS = "getRelativeBoxInfos"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_SHOPS = "setShops",
  SET_SHOP_COUNT = "setShopCount",
  SET_SHOP = "setShop",
  SET_BUSINESS_DISTRICTS = "setBusinessDistricts",
  SET_BUSINESS_DISTRICT_COUNT = "setBusinessDistrictCount",
  SET_BUSINESS_DISTRICT = "setBusinessDistrict",
  SET_ADMINS = "setAdmins",
  SET_ADMIN_COUNT = "setAdminCount",
  SET_ADMIN = "setAdmin",
  SET_DISTRIBUTION_USERS = "setDistributionUsers",
  SET_DISTRIBUTION_USER_COUNT = "setDistributionUserCount",
  SET_DISTRIBUTIONS = "setDistributions",
  SET_DISTRIBUTION_COUNT = "setDistributionCount",
  SET_DISTRIBUTION = "setDistribution",
  SET_ORDERS = "setOrders",
  SET_ORDER_COUNT = "setOrderCount",
  SET_ORDER = "setOrder",
  SET_USERS = "setUsers",
  SET_USER_COUNT = "setUserCount",
  SET_BUDGET_YEARS = "setBudgetYears",
  SET_BUDGET_YEAR_OPTIONS = "setBudgetYearOptions",
  SET_BUDGET_YEAR = "setBudgetYear",
  SET_BUDGET_QUARTERS = "setBudgetQuarters",
  SET_BUDGET_QUARTER = "setBudgetQuarter",
  SET_SHIFT_BY_BUDGET_QUARTERS = "setShiftByBudgetQuarters",
  SET_COUPON_ITEMS = "setCouponItems",
  SET_COUPON_ITEM_COUNT = "setCouponItemCount",
  SET_COUPON_ITEM = "setCouponItem",
  SET_COUPON_DELIVER_EVENTS = "setCouponDeliverEvents",
  SET_COUPON_DELIVER_EVENT = "setCouponDeliverEvent",
  SET_COUPON_SETTINGS = "setCouponSettings",
  SET_COUPON_DELIVER_EVENT_ITEMS = "setCouponDeliverEventItems",
  SET_EVENTS = "setEvents",
  SET_EVENT_COUNT = "setEventCount",
  SET_EVENT = "setEvent",
  SET_ROLES = "getRoles",
  SET_ROLE_COUNT = "setRoleCount",
  SET_SHOP_LOGIN = "setShopLogin",
  SET_EVENT_INVITATIONS = "setEventInvitations",
  SET_SYSTEM_SETTING = "set_system_setting",
  SET_GIFT_COUPONS = "set_gift_coupons",
  SET_EVENT_INVITATION_COUNT = "set_event_invitation_count",
  SET_EVENT_REGISTRATIONS = "set_event_registrations",
  SET_EVENT_REGISTRATION_COUNT = "set_event_registration_count",
  SET_NEWS_ITEMS = "setNewsItems",
  SET_NEWS_ITEM_COUNT = "setNewsItemCount",
  SET_NEWS_ITEM = "setNewsItem",

  SET_MEMBERS = "setMembers",
  SET_MEMBER_COUNT = "setMemberCount",
  SET_MEMBER = "setMember",

  SET_REPORT_DAY = "setReportDay",
  SET_REPORT_DAYS = "setReportDays",
  SET_REPORT_DAY_COUNT = "setReportDayCount",

  SET_PRODUCT_BRANDS = "setProductBrands",
  SET_PRODUCT_BRAND_COUNT = "setProductBrandCount",
  SET_PRODUCT_BRAND = "setProductBrand",
  SET_COMPOUNDS = "setCompounds",
  SET_COMPOUND_COUNT = "setCompoundCount",
  SET_COMPOUND = "setCompound",
  SET_IPS = "setIps",
  SET_IP_COUNT = "setIpCount",
  SET_IP = "setIp",
  SET_PRODUCTS = "setProducts",
  SET_ALL_PRODUCTS = "setAllProducts",
  SET_PRODUCT_COUNT = "setProductCount",
  SET_PRODUCT = "setProduct",
  SET_DOWNLOAD_PRODUCTS = "setDownloadProducts",
  SET_DOWNLOAD_PAYMENT_RECORDS = "setDownloadPaymentRecords",
  SET_DOWNLOAD_ADD_BALANCE_RECORDS = "setDownloadAddBalanceRecords",
  SET_DOWNLOAD_GIFT_RECORDS = "setDownloadGiftRecords",
  SET_DOWNLOAD_MEMBERS = "setDownloadMembers",
  SET_DOWNLOAD_LOSER_ITEMS = "setDownloadLoserItems",
  SET_BOX_INFOS = "setBoxInfos",
  SET_BOX_INFO_COUNT = "setBoxInfoCount",
  SET_BOX_INFO = "setBoxInfo",
  SET_BOX_TYPES = "setBoxTypes",
  SET_GAME_TYPES = "setGameTypes",
  SET_BOX_ITEM_LEVELS = "setBoxItemLevels",
  SET_BOX_ITEM_TYPES = "setBoxItemTypes",
  SET_BOX_LEVEL_TYPES = "setBoxLevelTypes",
  SET_BOX_LEVEL_ITEM_INFO_GIFT_TYPES = "setBoxLevelItemInfoGiftTypes",
  SET_BOXES = "setBoxes",
  SET_BOX_COUNT = "setBoxCount",
  SET_BOX_RECORDS = "setBoxRecords",
  SET_BOX_RECORD_COUNT = "setBoxRecordCount",

  SET_BOX_CATEGORIES = "setBoxCategories",
  SET_BOX_CATEGORY_COUNT = "setBoxCategoryCount",
  SET_BOX_CATEGORY = "setBoxCategory",

  SET_PRODUCT_BRAND_OPTIONS = "setProductBrandOptions",
  SET_PRODUCT_IP_OPTIONS = "setProductIpOptions",
  SET_PRODUCT_RECYCLE_TYPE_OPTIONS = "setProductRecycleTypeOptions",
  SET_BANNERS = "setBanners",

  SET_BOX_INFO_BOX_TYPE_OPTIONS = "setBoxInfoBoxTypeOptions",

  SET_REMOVE_BALANCE_RECORDS = "setRemoveBalanceRecords",
  SET_REMOVE_BALANCE_RECORD_COUNT = "setRemoveBalanceRecordCount",
  SET_REMOVE_BALANCE_RECORD = "setRemoveBalanceRecord",
  SET_PAYMENT_RECORDS = "setPaymentRecords",
  SET_PAYMENT_RECORD_COUNT = "setPaymentRecordCount",
  SET_ADD_BALANCE_RECORDS = "setAddBalanceRecords",
  SET_ADD_BALANCE_RECORD_COUNT = "setAddBalanceRecordCount",
  SET_ADD_BALANCE_RECORD = "setAddBalanceRecord",
  SET_ADD_POINT_RECORDS = "setAddPointRecords",
  SET_ADD_POINT_RECORD_COUNT = "setAddPointRecordCount",
  SET_ADD_POINT_RECORD = "setAddPointRecord",
  SET_ADD_EXTRA_POINT_RECORDS = "setAddExtraPointRecords",
  SET_ADD_EXTRA_POINT_RECORD_COUNT = "setAddExtraPointRecordCount",
  SET_ADD_EXTRA_POINT_RECORD = "setAddExtraPointRecord",
  SET_SHIPPING_ORDERS = "setShippingOrders",
  SET_SHIPPING_ORDER_COUNT = "setShippingOrderAddPointRecordCount",
  SET_SHIPPING_ORDER = "setShippingOrder",
  SET_SUMMARY = "setSummary",
  SET_WATER_LIST = "setWaterList",
  SET_LOSER_LIST = "setLoserList",
  SET_CARD_RECORDS = "setCardRecords",
  SET_CARD_RECORD_COUNT = "setCardRecordCount",
  SET_WATER_ITEMS = "setWaterItems",
  SET_WATER_ITEM_COUNT = "setWaterItemCount",
  SET_LOSER_ITEMS = "setLoserItems",
  SET_LOSER_ITEM_COUNT = "setLoserItemCount",
  SET_EVENT_OPTIONS = "setEventOptions",
  SET_BOX_PRODUCTS = "setBoxProducts",
  SET_LEVELS = "setLevels",
  SET_GIFT_RECORD = "setGiftRecord",
  SET_GIFT_RECORDS = "setGiftRecords",
  SET_GIFT_RECORD_COUNT = "setGiftRecordCount",
  SET_RELATIVE_BOX_INFOS = "setRelativeBoxInfos"
}

export { Actions, Mutations };
