import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"
import { AxiosRequestConfig } from "axios"

export interface AddBalanceRecord {
  id: number
  balance: number
}

export interface AddBalanceRecordInfo {
  add_balance_records: Array<AddBalanceRecord>
  add_balance_record: AddBalanceRecord
}

@Module
export default class AddBalanceRecordModule
  extends VuexModule
  implements AddBalanceRecordInfo
{
  add_balance_records = [] as Array<AddBalanceRecord>
  add_balance_record_count = 0
  add_balance_record = {} as AddBalanceRecord
  download_payment_records = {} as any
  download_add_balance_records = {} as any

  get currentAddBalanceRecord(): AddBalanceRecord {
    return this.add_balance_record
  }

  get currentAddBalanceRecords(): Array<AddBalanceRecord> {
    return this.add_balance_records
  }

  get currentAddBalanceRecordCount() {
    return this.add_balance_record_count
  }

  get currentDownloadPaymentRecords(){
    return this.download_payment_records
  }

  get currentDownloadAddBalanceRecords(){
    return this.download_add_balance_records
  }

  // get getErrors() {
  //   return this.errors;
  // }

  @Mutation
  [Mutations.SET_ADD_BALANCE_RECORDS](add_balance_records) {
    this.add_balance_records = add_balance_records
  }

  @Mutation
  [Mutations.SET_ADD_BALANCE_RECORD](add_balance_record) {
    this.add_balance_record = add_balance_record
  }

  @Mutation
  [Mutations.SET_ADD_BALANCE_RECORD_COUNT](add_balance_record_count) {
    this.add_balance_record_count = add_balance_record_count
  }

  @Mutation
  [Mutations.SET_DOWNLOAD_PAYMENT_RECORDS](download_payment_records) {
    this.download_payment_records = download_payment_records
  }

  @Mutation
  [Mutations.SET_DOWNLOAD_ADD_BALANCE_RECORDS](download_add_balance_records) {
    this.download_add_balance_records = download_add_balance_records
  }

  @Action
  [Actions.GET_ADD_BALANCE_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get(`admin/add_balance_records`, queryString)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_ADD_BALANCE_RECORDS,
            data["add_balance_records"]
          );
          this.context.commit(
            Mutations.SET_ADD_BALANCE_RECORD_COUNT,
            data["total_result"]
          );
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.CREATE_ADD_BALANCE_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.post(`admin/add_balance_records`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          this.context.commit(
            Mutations.SET_ADD_BALANCE_RECORD,
            data["add_balance_record"]
          )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.ABORT_ADD_BALANCE_RECORD](params) {
    ApiService.setHeader()

    return new Promise<void>((resolve, reject) => {
      ApiService.put(`admin/add_balance_records/${params.id}/abort`, params)
        .then(({ data }) => {
          if (!data.success) {
            this.context.commit(Mutations.SET_ERROR, data.error_msg)
            reject()
          }

          // this.context.commit(
          //   Mutations.SET_ADD_BALANCE_RECORD,
          //   data["add_balance_record"]
          // )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_DOWNLOAD_PAYMENT_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/add_balance_records/download_wx_excel", queryString, {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DOWNLOAD_PAYMENT_RECORDS,
            data
          )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }

  @Action
  [Actions.GET_DOWNLOAD_ADD_BALANCE_RECORDS](params) {
    ApiService.setHeader()

    let queryString = "?"

    if (params) {
      Object.keys(params).forEach((i) => {
        queryString += "&" + i + "=" + params[i]
      })
    }

    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/add_balance_records/download_excel", queryString, {responseType: 'blob'})
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_DOWNLOAD_ADD_BALANCE_RECORDS,
            data
          )
          resolve()
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          reject()
        })
    })
  }
}
